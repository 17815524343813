<template>
  <div class="px-3 mt-5">
    <div class="row">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="col-sm-12 text-start" @submit.prevent="handleSubmit(submit)">
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-3">
              <label for="userName" class="text-muted mb-2">使用者名稱</label>
              <ValidationProvider rules='required|username' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="text" class="form-control" id="userName"
                    v-model="user.userName"
                    placeholder="使用者名稱">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6 mb-3">
              <label for="email" class="text-muted mb-2">Email</label>
              <ValidationProvider rules='email' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="email" class="form-control" id="email"
                    v-model="user.email"
                    placeholder="email">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-3">
              <label for="Password" class="text-muted mb-2">密碼</label>
              <ValidationProvider rules='required|password|password2:@confirm' v-slot='{ errors , classes }'>
                <div :class="classes" class="position-relative">
                  <input
                   class="form-control" id="Password"
                    v-model="user.password"
                    :type="showPsd ? 'text' : 'password'"
                    placeholder="密碼">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                  <i class="bi bi-eye-fill psd_visible position-absolute"
                    @click.prevent="showPsd=true" v-if="!showPsd"></i>
                  <i class="bi bi-eye-slash-fill psd_invisible position-absolute"
                    @click.prevent="showPsd=false" v-if="showPsd"></i>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6 mb-3">
              <label for="confirmPassword" class="text-muted mb-2">再次確認密碼</label>
              <ValidationProvider name="confirm" rules='required' v-slot='{ errors , classes }'>
                <div :class="classes" class="position-relative">
                  <input class="form-control" id="confirmPassword"
                    v-model="user.confirmPassword"
                    :type="showConfirmPsd ? 'text' : 'password'"
                    placeholder="密碼">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                  <i class="bi bi-eye-fill psd_visible position-absolute"
                    @click.prevent="showConfirmPsd=true" v-if="!showConfirmPsd"></i>
                  <i class="bi bi-eye-slash-fill psd_invisible position-absolute"
                    @click.prevent="showConfirmPsd=false" v-if="showConfirmPsd"></i>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12 text-end">
              <button class="btn btn-main">儲存</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__Creator',
  data() {
    return {
      user: {
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      showPsd: false,
      showConfirmPsd: false,
      metaTitle: '新增使用者 / 使用者 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "新增使用者 / 使用者 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '新增使用者 / 使用者 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    uploadFile() {
      const uploadedFile = this.$refs.files.files[0];
      this.$log.debug(uploadedFile);
      const vm = this;
      const formData = new FormData();
      formData.append('upload', uploadedFile);
      vm.fileUploading = 'loading';
      vm.$http.post(`${vm.$API_IMGPATH}/Avatar`, formData)
        .then((response) => {
          this.$log.debug(response.data);
          vm.$refs.img.src = `${response.data.url}`;
        // vm.avatarImg = response.data.url;
        // vm.fileUploading = 'done';
        }).catch((err) => {
        // vm.fileUploading = 'fail';
          Swal.fire(
            '',
            '找不到對應的課程資料',
            'info',
          );
          this.$log.debug(err.response);
        });
    },
    submit() {
      const data = {
        userName: this.user.userName,
        email: this.user.email,
        password: this.user.password,
        passwordConfirm: this.user.confirmPassword,
      };
      const vm = this;
      // console.log(data);
      this.updateLoading(true);
      this.$http.post(`${this.$API_PATH}/Admin/Member/Management`, data, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      })
        .then((response) => {
          this.$log.debug(response.data);
          this.updateLoading(false);
          Swal.fire(
            '儲存成功',
            `${response.data.message}`,
            'success',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/admin/users');
            }
          });
        }).catch((err) => {
        // vm.fileUploading = 'fail';
          this.$log.debug(err.response);
          this.updateLoading(false);
          if (err.response) {
            if (err.response.status == 401) {
              Swal.fire(
                '',
                '權限不足',
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/');
                }
              });
            }
          } else {
            Swal.fire(
              '錯誤',
              '請稍候再試',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userAuth', 'LoginProvider']),
  },
  created() {
    this.role = this.$route.params.role;
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }
</style>
